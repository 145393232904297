#content .container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

#content .container .gallery {
  display: flex;
  gap: 1rem;
}

#content .container .cta {
  text-align: center;
}

#content .container .bottom {
  display: flex;
  justify-content: center;
  gap: 1rem;
}

#content .container .bottom #ctaWpp,
#content .container .bottom #ctaTel {
  width: 20%;
}

#content .container .bottom #ctaWpp img,
#content .container .bottom #ctaTel img {
  width: 100%;
}


/* ==================== MEDIA QUERIES (MD) ==================== */
@media screen and (max-width: 1024px) {
  #content .container .gallery {
    flex-direction: column;
    align-items: center;
  }

  #content .container .gallery figure {
    width: 80%;
  }

  #content .container .bottom #ctaWpp,
  #content .container .bottom #ctaTel {
    width: 35%;
  }
}

/* ==================== MEDIA QUERIES (SM) ==================== */
@media screen and (max-width: 600px) {
  #content .container .gallery figure {
    width: 90%;
  }

  #content .container .bottom #ctaWpp,
  #content .container .bottom #ctaTel {
    width: 45%;
  }
}