#header .container {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 2rem;
    position: absolute;
    top: 12vh;
    left: 9vw;
    z-index: 2;
}

#header .container .title {
    text-transform: uppercase;
    color: white;
    width: 60%;
}

#header .container .cta {
    color: white;
}

#header .container .bottom {
    display: flex;
    gap: 1rem;
}

/* ==================== MEDIA QUERIES (MD) ==================== */
@media screen and (max-width: 1024px) {
    #header .container {
        position: relative;
        top: 0;
        left: 0;
        align-items: center;
        margin: 0 auto;
    }

    #header .container .title {
        display: none;
    }

    #header .container .cta {
        color: black;
    }

    #header .banner img {
        width: 100%;
    }
}

/* ==================== MEDIA QUERIES (SM) ==================== */
@media screen and (max-width: 600px) {
    
}