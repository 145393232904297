#about .about1 .container {
  display: flex;
  align-items: center;
  gap: 2rem;
}

#about .about1 .container .left {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 100%;
}

#about .about1 .container .left .top {
  display: flex;
  gap: .5rem;
}

#about .about1 .container .left .top .design {
  width: 1%;
  margin-left: -1.5rem;
}

#about .about1 .container .left .bottom {
  display: flex;
  gap: 1rem;
}

#about .about1 .container .left .bottom #ctaWpp,
#about .about1 .container .left .bottom #ctaTel {
  width: 30%;
}

#about .about1 .container .right { 
  width: 50%;
}

#about .about1 .container .right .top {
  display: none;
}

#about .about1 .container .right img {
  border-radius: 5px;
}

#about .about2 {
  background-color: #303030;
}

#about .about2 .container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

#about .about2 .container .top {
  display: flex;
  gap: .5rem;
}

#about .about2 .container .top .design {
  width: .5%;
  margin-left: -1.5rem;
}

#about .about2 .container .top .title {
  color: white;
}

#about .about2 .container .text {
  color: #D0D0D0;
}

#about .about2 .container .gallery {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

#about .about2 .container .gallery .item figure img {
  border-radius: 5px;
}

#about .about2 .container .gallery .item .cta {
  margin-top: 2rem;
}

#about .about2 .container .gallery .item .cta img {
  width: 80%;
}

#about .about3 {
  background-color: #EFEFEF;
}

#about .about3 .container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

#about .about3 .container .top {
  display: flex;
  gap: .5rem;
}

#about .about3 .container .top .design {
  width: .5%;
  margin-left: -1.5rem;
}

#about .about3 .container .top .title {
  color: black;
}

#about .about3 .container .text {
  color: #45464B;
}

#about .about3 .container .gallery {
  display: flex;
  justify-content: center;
  gap: 1rem;
}

#about .about3 .container .gallery .item figure img {
  border-radius: 5px;
}

#about .about3 .container .gallery .item .cta {
  margin-top: -5rem;
}

#about .about3 .container .gallery .item .cta img {
  width: 80%;
}

#about .about4 .container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

#about .about4 .container .top {
  display: flex;
  gap: .5rem;
}

#about .about4 .container .top .design {
  width: .5%;
  margin-left: -1.5rem;
}

#about .about4 .container .top .title {
  color: black;
}

#about .about4 .container .text {
  color: #45464B;
}

/* ==================== MEDIA QUERIES (MD) ==================== */
@media screen and (max-width: 1024px) {
  #about .about1 .container {
    flex-direction: column;
  }

  #about .about1 .container .left {
    text-align: center;
  }
  
  #about .about1 .container .left .top {
    display: none;
  }
  
  #about .about1 .container .left .bottom #ctaWpp,
  #about .about1 .container .left .bottom #ctaTel {
    width: 100%;
  }
  
  #about .about1 .container .right { 
    width: 100%;
    order: -1;
  }

  #about .about1 .container .right .top { 
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-bottom: 2rem;
  }

  #about .about1 .container .right .top figure img {
    width: 35%;
    max-height: 3px;
  }
  
  #about .about2 .container .top {
    flex-direction: column;
    align-items: center;
  }

  #about .about2 .container .top .design {
    width: 100%;
    margin-left: 0;
  }
  
  #about .about2 .container .top .design img {
    width: 35%;
    max-height: 3px;
    margin-left: 0;
  }
  
  #about .about2 .container .top .title {
    order: -1;
  }
  
  #about .about2 .container .text {
    text-align: center;
  }
  
  #about .about2 .container .gallery {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas: repeat(2, 1fr);
  }

  #about .about3 {
    background-color: #EFEFEF;
  }
  
  #about .about3 .container {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }
  
  #about .about3 .container .top {
    flex-direction: column;
    align-items: center;
  }
  
  #about .about3 .container .top .design {
    width: 100%;
    margin-left: 0;
  }
  
  #about .about3 .container .top .design img {
    width: 35%;
    max-height: 3px;
    margin-left: 0;
  }
  
  #about .about3 .container .top .title {
    color: black;
    order: -1;
  }
  
  #about .about3 .container .text {
    text-align: center;
  }
  
  #about .about3 .container .gallery {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas: repeat(2, 1fr);
    gap: 3rem;
  }

  #about .about3 .container .gallery .item .cta {
    margin-top: -6rem;
  }

  #about .about4 .container .top {
    flex-direction: column;
    align-items: center;
  }

  #about .about4 .container .top .design {
    width: 100%;
    margin-left: 0;
  }
  
  #about .about4 .container .top .design img {
    width: 35%;
    max-height: 3px;
    margin-left: 0;
  }
  
  #about .about4 .container .top .title {
    order: -1;
  }
}

/* ==================== MEDIA QUERIES (SM) ==================== */
@media screen and (max-width: 600px) {
  #about .about3 .container .gallery {
    gap: 1rem;
  }

  #about .about3 .container .gallery .item .cta {
    margin-top: -3rem;
  }
}