#menu .container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem 0;
}

#menu .container figure img {
    width: 10%;
}

/* ==================== MEDIA QUERIES (MD) ==================== */
@media screen and (max-width: 1024px) {
    #menu .container figure img {
        width: 30%;
    }
}

/* ==================== MEDIA QUERIES (SM) ==================== */
@media screen and (max-width: 600px) {
    
}