#ctaTel {
    width: 15%;
}

/* ==================== MEDIA QUERIES (MD) ==================== */
@media screen and (max-width: 1024px) {
    #ctaTel {
        width: 50%;
        text-align: left;
    }

    #ctaTel img {
        width: 70%;
    }
}

/* ==================== MEDIA QUERIES (SM) ==================== */
@media screen and (max-width: 600px) {
    #ctaTel img {
        width: 90%;
    }
}