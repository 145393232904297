#ctaWpp {
    width: 15%;
}

/* ==================== MEDIA QUERIES (MD) ==================== */
@media screen and (max-width: 1024px) {
    #ctaWpp {
        width: 50%;
        text-align: right;
    }

    #ctaWpp img {
        width: 70%;
    }
}

/* ==================== MEDIA QUERIES (SM) ==================== */
@media screen and (max-width: 600px) {
    #ctaWpp img {
        width: 90%;
    }
}